.header-area {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    z-index: 1000;
    height: 100px;
    background: #fff;
    box-shadow: 0 2px 24px 0 rgba(0,0,0,0.21);
  }
  
  .header-area .main-nav .logo {
    float: left;
    margin-top: 35px;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
  }

  .header-area .main-nav .logo-margin{
    margin-top: 21px !important;
  }
  
  .header-area .main-nav .logo img {
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
  }
  
  .header-area .main-nav .nav {
    float: right;
    margin-top: 30px;
    margin-left: 0px;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
  }
  
  .header-area .main-nav .nav li {
    padding-left: 10px;
    padding-right: 20px;
  }
  
  /* .header-area .main-nav .nav li:last-child {
    padding-right: 0px;
  } */

.header-area .language-switch-div{
  padding: 7px 10px;
  border: 3px solid #FCCC6A;
  cursor: pointer;
  margin-top: -3px;
}

.header-area .language-switch-div:hover{
  color: #FCCC6A;
}

  .header-area .main-nav .nav li a {
    display: block;
    font-weight: 400;
    color: #212121;
    font-size: 16px;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    height: 40px;
    line-height: 40px;
    border: transparent;
    letter-spacing: 1.58px;
    padding: 0px 10px;
  }
  
  .header-area .main-nav .nav li a:hover {
    color: #212121;
  }
  
  .header-area .main-nav .nav li a.contact-btn {
    padding-left: 25px;
    padding-right: 25px;
    border: 1px solid #1F2128;
    height: 35px;
    margin-top: 2px;
    line-height: 34px;
    letter-spacing: 0.5px;
    background: none;
    color: #212121;
    position: relative;
    overflow: hidden;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
  }
  
  .header-area .main-nav .nav li a.contact-btn:hover {
    background: #1F2128;
    color: #fff;
  }
  
  .header-area .main-nav .nav li.submenu {
    position: relative;
    padding-right: 35px;
  }
  
  .header-area .main-nav .nav li.submenu:after {
    font-family: FontAwesome,sans-serif;
    content: "\f107";
    font-size: 12px;
    color: #212121;
    position: absolute;
    right: 32px;
    top: 12px;
  }
  
  .header-area .main-nav .nav li.profile-header:after {
    font-family: none;
    content: "";
    font-size: 12px;
    color: #212121;
    position: absolute;
    right: 32px;
    top: 12px;
  }

  .header-area .main-nav .nav li.profile-header ul {
    position: absolute;
    width: 200px !important;
    box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.08);
    overflow: hidden;
    top: 50px !important;
    right: -50px !important;
    opacity: 0;
    transform: translateY(-2em);
    visibility: hidden;
    z-index: -1;
    transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s, z-index 0s linear 0.01s;
  }

  .header-area .main-nav .nav li.submenu ul {
    position: absolute;
    width: 200px;
    box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.08);
    overflow: hidden;
    top: 40px;
    opacity: 0;
    transform: translateY(-2em);
    visibility: hidden;
    z-index: -1;
    transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s, z-index 0s linear 0.01s;
  }
  
  .header-area .main-nav .nav li.submenu ul li {
    margin-left: 0px;
    padding-left: 0px;
    padding-right: 0px;
  }

  .header-area .main-nav .nav li.submenu ul li a {
    display: block;
    background: #fff;
    color: #212121;
    padding-left: 20px;
    height: 40px;
    line-height: 40px;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    position: relative;
    font-size: 13px;
  }
  

  .header-area .main-nav .nav li.submenu ul li a:before {
    content: '';
    position: absolute;
    width: 0px;
    height: 40px;
    left: 0px;
    top: 0px;
    bottom: 0px;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    background: #1F2128;
  }
  
  .header-area .main-nav .nav li.submenu ul li a:hover {
    background: #fafafa;
    padding-left: 25px;
  }
  
  .header-area .main-nav .nav li.submenu ul li a:hover:before {
    width: 1px;
  }
  
  .header-area .main-nav .nav li.submenu:hover ul {
    visibility: visible;
    opacity: 1;
    z-index: 1;
    transform: translateY(0%);
    transition-delay: 0s, 0s, 0.3s;
  }
  
  .header-area .main-nav .menu-trigger {
    cursor: pointer;
    position: absolute;
    top: 23px;
    width: 32px;
    height: 40px;
    text-indent: -9999em;
    z-index: 99;
    right: 40px;
    display: none;
  }
  
  .header-area .main-nav .menu-trigger span,
  .header-area .main-nav .menu-trigger span:before,
  .header-area .main-nav .menu-trigger span:after {
    -moz-transition: all 0.4s;
    -o-transition: all 0.4s;
    -webkit-transition: all 0.4s;
    transition: all 0.4s;
    background-color: #212121;
    display: block;
    position: absolute;
    width: 30px;
    height: 2px;
    left: 0;
  }
  
  .header-area .main-nav .menu-trigger span:before,
  .header-area .main-nav .menu-trigger span:after {
    -moz-transition: all 0.4s;
    -o-transition: all 0.4s;
    -webkit-transition: all 0.4s;
    transition: all 0.4s;
    background-color: #212121;
    display: block;
    position: absolute;
    height: 2px;
    left: 0;
    width: 75%;
  }
  
  .header-area .main-nav .menu-trigger span:before,
  .header-area .main-nav .menu-trigger span:after {
    content: "";
  }
  
  .header-area .main-nav .menu-trigger span {
    top: 16px;
  }
  
  .header-area .main-nav .menu-trigger span:before {
    -moz-transform-origin: 33% 100%;
    -ms-transform-origin: 33% 100%;
    -webkit-transform-origin: 33% 100%;
    transform-origin: 33% 100%;
    top: -10px;
    z-index: 10;
  }
  
  .header-area .main-nav .menu-trigger span:after {
    -moz-transform-origin: 33% 0;
    -ms-transform-origin: 33% 0;
    -webkit-transform-origin: 33% 0;
    transform-origin: 33% 0;
    top: 10px;
  }
  
  .header-area .main-nav .menu-trigger.active span,
  .header-area .main-nav .menu-trigger.active span:before,
  .header-area .main-nav .menu-trigger.active span:after {
    background-color: transparent;
    width: 100%;
  }
  
  .header-area .main-nav .menu-trigger.active span:before {
    -moz-transform: translateY(6px) translateX(1px) rotate(45deg);
    -ms-transform: translateY(6px) translateX(1px) rotate(45deg);
    -webkit-transform: translateY(6px) translateX(1px) rotate(45deg);
    transform: translateY(6px) translateX(1px) rotate(45deg);
    background-color: #212121;
  }
  
  .header-area .main-nav .menu-trigger.active span:after {
    -moz-transform: translateY(-6px) translateX(1px) rotate(-45deg);
    -ms-transform: translateY(-6px) translateX(1px) rotate(-45deg);
    -webkit-transform: translateY(-6px) translateX(1px) rotate(-45deg);
    transform: translateY(-6px) translateX(1px) rotate(-45deg);
    background-color: #212121;
  }

  .locat_headerapp{
    display: block;
    font-weight: 400;
    color: #212121;
    font-size: 13px;
    letter-spacing: 1.44px;
    transition: all 0.3s ease 0s;
    height: 40px;
    line-height: 40px;
    border: transparent;
    padding: 0px 10px;
    cursor: pointer;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .loc_innerwrap{
    float: left;
    margin-top: 30px;
    margin-left:4%;
    cursor: pointer;
    width: 29%;
  }
  .loc_innerwrapeng{
    float: left;
    margin-top: 30px;
    margin-left:4%;
    cursor: pointer;
    width: 29%;
  }
  .loc_innerwrapone{
    float: left;
    margin-right: -13px;
    text-decoration-line: underline;
    text-transform: capitalize;
    font-weight: bold;
    width: 20%;
    padding-bottom: 11px
  }
  .loc_innerwraptwo{
    float: left;
    color: #000000ad;
    width: auto;
    max-width: 70%;
  }
  .locat_headerappcap{
    display: block;
    font-weight: 600;
    color: #212121;
    font-size: 15px;
    transition: all 0.3s ease 0s;
    height: 40px;
    line-height: 40px;
    border: transparent;
    padding: 0px 10px;
    cursor: pointer;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .downapp_angle{
    color: orange;
    font-size: 18px;
    padding-top: 2px;
  }
  .font_angkechange{
    float: left;
    padding-top: 8px;
  }
  .pwd_fieldsinnerlocate{
    outline: none;
    width: 100%;
    padding-left: 15px;
    font-size: 16px;
    height: 50px;
    border: 1px solid #EEEEEE;
    margin-bottom: 3%;
    font-weight: 500;
    padding-right: 9%;
  }
  .lo_ero_map{
    text-align: center;font-weight: 600;padding-top:5%;
  }
  .lo_ero_mapwrap{
    text-align: center;padding-top:2%;width:100%;float:left;
  }
  .error_imagemap{
    text-align: center;
    padding-top: 6%;
  }
  .error_imagemapwrap{
    width: 215px;
    height: 215px;
  }
  @media (max-width: 1200px) {
    .header-area .main-nav .nav li {
      padding-left: 12px;
      padding-right: 12px;
    }
    .header-area .main-nav .nav li.submenu {
      padding-right: 20px;
    }
    .header-area .main-nav .nav li.submenu:after {
      right: 5px;
    }
  }
  
  @media (max-width: 991px) {
    .header-area {
      background: #fff;
      height: 125px;
      box-shadow: 0 2px 24px 0 rgba(0,0,0,0.21);
    }
    .loc_innerwrap{
      margin-top:0px;
      width:100%;
    }
    .loc_innerwrapeng{
      margin-top:10px;
      width:100%;
    }
    .ant-drawer-content-wrapper{
      width: 375px!important;
    }
    .header-area .container {
      padding: 0px;
    }
    .header-area .logo {
      margin-left: 30px;
      margin-top: 20px !important;
    }
    .header-area .menu-trigger {
      display: block !important;
    }
    .header-area .main-nav {
      overflow: hidden;
    }
    .header-area .main-nav .nav {
      float: left;
      width: 100%;
      margin-top: 20px !important;
      display: none;
      -webkit-transition: all 0s ease 0s;
      -moz-transition: all 0s ease 0s;
      -o-transition: all 0s ease 0s;
      transition: all 0s ease 0s;
      margin-left: 0px;
    }
    .header-area .main-nav .nav li {
      width: 100%;
      background: #fff;
      border-bottom: 1px solid #f5f5f5;
      padding-left: 0px !important;
      padding-right: 0px !important;
    }
    .header-area .main-nav .nav li a {
      height: 50px !important;
      line-height: 50px !important;
      padding: 0px !important;
      padding-left: 30px !important;
      border: none !important;
      background: #fff !important;
      color: #1F2128 !important;
    }
    .header-area .main-nav .nav li a:hover {
      background: #f5f5f5 !important;
    }
    .header-area .main-nav .nav li a.contact-btn {
      margin-top: 0px;
      -webkit-border-radius: 0px;
      -moz-border-radius: 0px;
      border-radius: 0px;
    }
    .header-area .main-nav .nav li.submenu ul {
      position: relative;
      visibility: inherit;
      opacity: 1;
      z-index: 1;
      transform: translateY(0%);
      transition-delay: 0s, 0s, 0.3s;
      top: 0px;
      width: 100%;
      box-shadow: none;
      height: 0px;
    }
    .header-area .main-nav .nav li.submenu ul li a {
      padding-left: 50px !important;
    }
    .header-area .main-nav .nav li.submenu ul li a:hover:before {
      width: 0px;
    }
    .header-area .main-nav .nav li.submenu ul.active {
      height: auto !important;
    }
    .header-area .main-nav .nav li.submenu:after {
      color: #212121;
      right: 25px;
      font-size: 14px;
      top: 15px;
    }
    .header-area .main-nav .nav li.submenu:hover ul, .header-area .main-nav .nav li.submenu:focus ul {
      height: 0px;
    }
  }

  .header-area .notification-oval{
    position: absolute;
    right: 19px;
  }
  
  @media (min-width: 992px) {
    .header-area .main-nav .nav {
      display: flex !important;
    }
  }

  @media (max-width: 1024px) {
    .header-area .notification-oval{
      position: absolute;
      right: 10px;
    }
  }
  