@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap");

.font-face-gm {
  font-family: "RBBold",sans-serif !important;
}

.font-face-gmlight {
  font-family: "RBLight",sans-serif !important;
}

.font-face-gmregular {
  font-family: "RBRegular,sans-serif",sans-serif !important;
}

body {
  background: #f5f5f5;
}

body[dir="rtl"] {
  direction: rtl;
  text-align: right;
}

.RightAlignArabic {
  text-align: right !important;
}

.float-right-arabic {
  float: right !important;
}

.float-left-arabic {
  float: left !important;
}

.rowremoveArabic {
  margin-left: 0px;
  margin-right: 0px;
}

/* width */
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* 
---------------------------------------------
reset
--------------------------------------------- 
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
div pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
figure,
header,
nav,
section,
article,
aside,
footer,
figcaption {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
}

.row:before {
  display: inline-block;
}

.row:after {
  display: inline-block;
}

.clearfix:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

.clearfix {
  display: inline-block;
}

.row:before {
  display: inline-block;
}

.row:after {
  display: inline-block;
}

html[xmlns] .clearfix {
  display: block;
}

* html .clearfix {
  height: 1%;
}

ul,
li {
  padding: 0;
  margin: 0;
  list-style: none;
}

.nav>li>a {
  padding: 0;
}

header,
nav,
section,
article,
aside,
footer,
hgroup {
  display: block;
}

* {
  box-sizing: border-box;
}

html,
body {
  font-family: "RBRegular", sans-serif !important;
  font-weight: 400;
  background-color: #fff;
  font-size: 16px;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust:100%;
  text-size-adjust:100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0px;
  margin-bottom: 0px;
}

ul {
  margin-bottom: 0px;
}

/* html,
body {
  background: #f5f5f5;
  color: #606060;
  font-family: "Montserrat", sans-serif;
} */

::selection {
  background: #1F2128;
  color: #fff;
}

::-moz-selection {
  background: #1F2128;
  color: #fff;
}

.section {
  padding-top: 110px;
  padding-bottom: 110px;
}

.section.white {
  background: #fff;
}

.left-heading .section-title {
  color: #212121;
  font-size: 28px;
  letter-spacing: 3.91px;
  line-height: 34px;
  font-weight: 500;
  margin-bottom: 30px;
}

.center-heading {
  text-align: center;
}

.center-heading .section-title {
  font-weight: 400;
  font-size: 28px;
  color: #1F2128;
  letter-spacing: 3.13px;
  line-height: 38px;
  margin-bottom: 20px;
}

.text_upper {
  text-transform: capitalize;
}

.center-text {
  text-align: center;
  font-weight: 400;
  font-size: 16px;
  color: #606060;
  line-height: 28px;
  letter-spacing: 1px;
  margin-bottom: 60px;
}

.center-text p {
  margin-bottom: 30px;
}

.left-text {
  font-weight: 400;
  color: #606060;
  font-size: 16px;
  letter-spacing: 1.6px;
  line-height: 28px;
}

.left-text p {
  margin-bottom: 30px;
}

.left-text p.dark {
  color: #1F2128;
}

.left-text ul li {
  color: #606060;
  font-size: 16px;
  letter-spacing: 1.6px;
  line-height: 19px;
  font-weight: 400;
  padding-left: 30px;
  position: relative;
  margin-bottom: 10px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

/* .left-text ul li:before {
  font-family: FontAwesome;
  content: "\f178";
  left: 0px;
  position: absolute;
} */

.left-text ul li:hover {
  padding-left: 40px;
}

.align-self-center {
  -ms-flex-item-align: center !important;
  align-self: center !important;
}

.align-self-bottom {
  -ms-flex-item-align: flex-end !important;
  align-self: flex-end !important;
}

.dark-btn {
  display: block;
  width: 195px;
  height: 45px;
  position: relative;
  overflow: hidden;
  border: 1px solid #1F2128;
}

.dark-btn .show-btn {
  position: absolute;
  top: 0px;
  cursor: pointer;
  left: 0px;
  width: 195px;
  height: 45px;
  line-height: 45px;
  text-align: center;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 1.35px;
  color: #1F2128;
}

.dark-btn .hide-btn {
  position: absolute;
  top: 0px;
  cursor: pointer;
  left: 195px;
  width: 195px;
  height: 45px;
  line-height: 45px;
  text-align: center;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 1.35px;
  color: #fff;
  background: #1F2128;
}

.dark-btn:hover .show-btn {
  left: -195px;
}

.dark-btn:hover .hide-btn {
  left: 0px;
}

.light-btn {
  display: block;
  width: 195px;
  height: 45px;
  position: relative;
  overflow: hidden;
  border: 1px solid #fff;
}

.light-btn .show-btn {
  position: absolute;
  top: 0px;
  cursor: pointer;
  left: 0px;
  width: 195px;
  height: 45px;
  line-height: 45px;
  text-align: center;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 1.35px;
  color: #fff;
}

.light-btn .hide-btn {
  position: absolute;
  top: 0px;
  cursor: pointer;
  left: 195px;
  width: 195px;
  height: 45px;
  line-height: 45px;
  text-align: center;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 1.35px;
  color: #1F2128;
  background: #fff;
}

.light-btn:hover .show-btn {
  left: -195px;
}

.light-btn:hover .hide-btn {
  left: 0px;
}

blockquote {
  margin-bottom: 28px;
  padding: 30px;
  padding-top: 40px;
  background: #fff;
  overflow: hidden;
}

blockquote .text {
  position: relative;
  text-align: center;
}

blockquote .text p {
  position: relative;
  width: 100%;
  z-index: 2;
  margin-bottom: 10px !important;
}

blockquote .text:before {
  font-family: FontAwesome,sans-serif;
  content: "\f10d";
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 1;
  font-size: 40px;
  color: #e1ecf4;
}

blockquote .text:after {
  font-family: FontAwesome,sans-serif;
  content: "\f10e";
  position: absolute;
  right: 0px;
  bottom: 0px;
  z-index: 1;
  font-size: 40px;
  color: #e1ecf4;
}

blockquote .author {
  color: #1F2128;
  text-align: center;
}

.page-gallery {
  height: 185px;
  display: block;
  overflow: hidden;
  margin-bottom: 30px;
}

.loader_center {
  width: 100%;
  text-align: center;
  left: 21%;
  top: 52%;
  margin-bottom: 0px;
}

.margin-bottom-0 {
  margin-bottom: 0px !important;
}

.margin-bottom-10 {
  margin-bottom: 10px !important;
}

.margin-bottom-20 {
  margin-bottom: 20px !important;
}

.margin-bottom-30 {
  margin-bottom: 30px !important;
}

.margin-bottom-40 {
  margin-bottom: 40px !important;
}

.margin-bottom-50 {
  margin-bottom: 50px !important;
}

.margin-bottom-60 {
  margin-bottom: 60px !important;
}

.margin-bottom-90 {
  margin-bottom: 90px !important;
}

.margin-top-10 {
  margin-top: 10px !important;
}

.margin-top-20 {
  margin-top: 20px !important;
}

.margin-top-30 {
  margin-top: 30px !important;
}

.margin-top-40 {
  margin-top: 40px !important;
}

.margin-top-50 {
  margin-top: 50px !important;
}

.margin-top-60 {
  margin-top: 60px !important;
}

.padding-top-0 {
  padding-top: 0px !important;
}

.padding-top-70 {
  padding-top: 70px !important;
}

.padding-bottom-120 {
  padding-bottom: 100px !important;
}

.padding-bottom-140 {
  padding-bottom: 140px !important;
}

.brand_colorred {
  color: #be325e !important;
}

.brand_coloryellow {
  color: #e5ab49 !important;
}

.brand_colorpurple {
  color: #202945 !important;
}

.brand_colorblue {
  color: #009ba2 !important;
}

.brand_colorbrown {
  color: #530644 !important;
}

.section_font {
  font-size: 30px !important;
}

.remv_space {
  letter-spacing: 0px !important;
}

@media (max-width: 991px) {

  html,
  body {
    overflow-x: hidden;
  }

  .mobile-bottom-fix {
    margin-bottom: 30px;
  }

  .page-gallery {
    height: 140px;
  }
}